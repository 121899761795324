const API_PREFIX = "https://api" + process.env.VUE_APP_DOMAIN;
export default {
  authority: `${API_PREFIX}/api/admin/permissions`,
  rolesList: `${API_PREFIX}/api/admin/roles`,
  userInfo: `${API_PREFIX}/api/web/users/user-info`,
  administrators: `${API_PREFIX}/api/admin`,
  users: `${API_PREFIX}/api/admin/users`,
  vip: `${API_PREFIX}/api/admin/vip`,
  course: `${API_PREFIX}/api/admin/course/series`,
  camps: `${API_PREFIX}/api/admin/camps`,
  projects: `${API_PREFIX}/api/admin/projects`,
  exam: `${API_PREFIX}/api/admin/question`,
};
