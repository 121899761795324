export default [
  {
    path: "",
    name: "plus",
    // redirect: { name: "CampsList" },
    component: { render: (h) => h("router-view") },
    meta: {
      nav: {
        icon: "el-icon-s-platform",
        title: "实战营",
      },
      breadcrumb: {
        title: "实战营",
      },
    },
    children: [
      {
        path: "plus",
        name: "TeachingAndResearch",
        redirect: { name: "CampsList" },
        component: { render: (h) => h("router-view") },
        meta: {
          nav: {
            title: "教研",
          },
          breadcrumb: {
            title: "教研",
            replace: true,
          },
        },
        children: [
          {
            path: "camps",
            name: "CampsList",
            redirect: { name: "Camps" },
            component: { render: (h) => h("router-view") },
            meta: {
              breadcrumb: {
                title: "实战营",
                replace: true,
              },
            },
            children: [
              {
                path: "list",
                name: "Camps",
                component: () => import("@/views/Camps/CampsList.vue"),
                permission: "camp-list",
                meta: {
                  title: "实战营",
                  nav: {
                    title: "实战营",
                  },
                },
              },
              {
                path: ":id",
                name: "Detail",
                props: true,
                component: () => import("@/views/Camps/CampsDetail.vue"),
                permission: "camp-detail",
                meta: {
                  title: "详情",
                },
              },
            ],
          },
          {
            path: "projects",
            name: "Projects",
            component: { render: (h) => h("router-view") },
            meta: {
              breadcrumb: {
                title: "项目",
                replace: true,
              },
            },
            children: [
              {
                path: "list",
                name: "projectList",
                props: true,
                component: () => import("@/views/Camps/Projects.vue"),
                //permission: "projects-list",
                meta: {
                  title: "项目",
                  nav: {
                    title: "项目",
                  },
                },
              },
              {
                path: ":id",
                name: "projectDetail",
                props: true,
                component: () => import("@/views/Camps/ProjectDetail.vue"),
                permission: "projects-detail",
                meta: {
                  title: "项目",
                  nav: {
                    title: "项目",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
