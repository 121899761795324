<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <the-lay-out-detail>
      <template v-slot:tree>
        <el-tree
          :data="data"
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </template>
      <template v-slot:divider>{{ dividerTitle }}</template>
      <template v-slot:form>
        <camp-info v-show="isBrief" :campsForm="campsForm"></camp-info>
        <project-info
          v-if="isProject"
          :projectData="projectData"
        ></project-info>
        <mavon-editor v-else-if="!isProject && isDetail" v-model="content" />
      </template>
    </the-lay-out-detail>
  </div>
</template>

<script>
import Camps from "@/global/service/camp.js";
import TheLayOutDetail from "../../components/TheLayOutDetail.vue";
import CampInfo from "./CampInfo.vue";
import ProjectInfo from "./ProjectInfo.vue";
export default {
  components: { TheLayOutDetail, CampInfo, ProjectInfo },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      disabled: true,
      defaultProps: {
        children: "children",
        label: "name",
      },
      data: [],

      campsForm: {},
      projectData: {},
      content: "",
      isProject: false,
      isBrief: true,
      isDetail: false,
      dividerTitle: "实战营基本信息",
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      Camps.getCampsDetail(this.id).then((res) => {
        this.data.push({
          id: res.data.id,
          name: res.data.name,
          children: [],
        });
        res.data.contents.map((data) => {
          if (data.content_type === "project") {
            Camps.getProjectsDetail(data.project.id).then((res) => {
              this.data[0].children.push({
                id: res.data.id,
                name: res.data.name,
                children: res.data.pages,
                content_type: "project",
              });
            });
          } else {
            this.data[0].children.push({
              name: data.page.name,
              id: data.page.id,
              content_type: "page",
            });
          }
        });
        this.campsForm = res.data;
        this.campsForm.is_online = String(this.campsForm.is_online);
      });
    },
    handleNodeClick(data) {
      if (data.id === Number(this.id)) {
        this.isBrief = true;
      } else {
        this.isBrief = false;
        if (data.content_type === "page") {
          this.isDetail = true;
          this.dividerTitle = data.name;
          let pageId = data.id;
          this.getPage(pageId);
        } else {
          if ("children" in data) {
            this.isProject = true;
            this.dividerTitle = "项目基本设置";
            Camps.getProjectsDetail(data.id).then((res) => {
              this.projectData = res.data;
            });
          } else {
            this.isProject = false;
            this.dividerTitle = "项目内容详情";
            this.content = data.contents[0].content.html;
          }
        }
      }
    },
    getPage(pageId) {
      Camps.getPageDetail(this.id, pageId).then((res) => {
        this.content = res.data.content.html;
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
