import Admin from "@/views/Authority/Admin";
import Role from "@/views/Authority/Role";

export default [
  {
    path: "",
    name: "Authority",
    component: { render: (h) => h("router-view") },
    meta: {
      nav: {
        svg: {
          class: "nav-svg",
          name: "homework",
        },
        title: "权限管理",
      },
      breadcrumb: {
        title: "权限管理",
      },
    },
    children: [
      {
        path: "authority",
        redirect: { name: "Admin" },
        component: { render: (h) => h("router-view") },
        meta: {
          breadcrumb: {
            title: "管理员",
            replace: true,
          },
        },
        children: [
          {
            path: "admin",
            name: "Admin",
            component: Admin,
            permission: "administrators-list",
            meta: {
              title: "管理员",
              nav: {
                title: "管理员",
              },
            },
          },
        ],
      },
      {
        path: "authority",
        component: { render: (h) => h("router-view") },
        meta: {
          breadcrumb: {
            title: "角色",
            //replace: true,
          },
        },
        children: [
          {
            path: "role",
            name: "Role",
            component: Role,
            permission: "roles-list",
            meta: {
              nav: {
                title: "角色",
              },
            },
          },
          {
            path: "add-role",
            name: "AddRole",
            permissions: ["roles-edit", "roles-add"],
            component: () => import("@/views/Authority/AddOrUpdateRole"),
            meta: {
              breadcrumb: {
                title: "添加",
                //replace: true,
              },
            },
          },
          {
            path: "edit-role/:role_id",
            name: "AddOrUpdateRole",
            permissions: "roles-detail",
            props: true,
            component: () => import("@/views/Authority/AddOrUpdateRole"),
            meta: {
              breadcrumb: {
                title: "详情",
              },
            },
          },
        ],
      },
    ],
  },
];
