<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <el-table stripe style="width: 100%" :data="tableData">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form
            label-width="100px"
            label-position="right"
            inline
            class="demo-table-expand"
          >
            <el-form-item
              class="options"
              v-for="(item, index) in props.row.options"
              :key="item.id"
              :label="
                item.correct === 1 ? '正确选项:' : '选项' + (index + 1) + ':'
              "
            >
              <el-input
                type="textarea"
                size="mini"
                v-model="item.content"
                :suffix-icon="item.correct === 1 ? 'el-icon-check' : ''"
                disabled
                >{{ item.content }}</el-input
              >
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="问题Id"
        prop="id"
      ></el-table-column>

      <el-table-column align="center" min-width="500" label="问题" prop="stem">
      </el-table-column>
      <el-table-column align="center" label="问题等级" prop="level">
      </el-table-column>
      <el-table-column align="center" label="操作" v-slot="scope">
        <el-button type="text" size="mini" @click="handleEdit(scope.row)"
          >详情</el-button
        >
        <el-divider class="vertical" direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click="handleDelete(scope.row)"
          >删除</el-button
        >
      </el-table-column>
    </el-table>
    <el-pagination
      :total="pagination.total"
      layout="prev, pager, next"
      background
      :page-size="per_page"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>
<script>
import Exam from "@/global/service/exam.js";
export default {
  data() {
    return {
      tableData: [],
      pagination: "",
      currentPage: 1,
      per_page: 20,
    };
  },
  created() {
    this.getStack();
  },
  methods: {
    getStack() {
      Exam.getQuestion({
        page: this.currentPage,
        page_size: this.per_page,
      }).then((res) => {
        this.tableData = res.data.data.list;
        this.pagination = res.data.data.pagination;
        console.log(res);
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getStack();
    },
  },
};
</script>
<style lang="less" scoped>
.options.el-form-item {
  display: block;
  margin-bottom: 10px;
  /deep/.el-form-item__content {
    width: calc(100% - 100px);
  }
  /deep/.el-textarea__inner {
    border-radius: 0;
  }
}
.vertical {
  vertical-align: text-top;
}
.el-pagination {
  text-align: center;
}
</style>
