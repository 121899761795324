<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <div>
      <div class="search-title">
        <div class="left">
          <el-input
            v-model="phone"
            size="medium"
            placeholder="手机号查询"
          ></el-input>
          <el-button
            type="primary"
            class="search_btn"
            size="medium"
            plain
            @click="searchVip"
            >查询</el-button
          >
          <el-button
            plain
            size="medium"
            class="search_btn"
            @click="resetVIPList"
            >重置</el-button
          >
        </div>
        <el-button
          type="primary"
          size="medium"
          class="add_btn"
          v-if="isPermission(addName)"
          @click="handleAddVip"
          >添加VIP用户</el-button
        >
      </div>
      <add-vip
        :visible.sync="dialogVisible"
        @close_dialog="handleClose"
      ></add-vip>
      <el-table border :data="tableData" style="width: 100%" align="center">
        <el-table-column align="center" label="ID" prop="id" width="50">
        </el-table-column>
        <el-table-column align="center" label="头像" prop="user">
          <template slot-scope="scope">
            <span class="avatar_container">
              <img class="avatar" :src="scope.row.user.avatar_url"
            /></span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="昵称" prop="user.nickname">
        </el-table-column>
        <el-table-column
          align="center"
          label="手机"
          prop="user.phone"
          min-width="65"
        >
        </el-table-column>
        <el-table-column align="center" label="vip套餐" prop="sku.name">
        </el-table-column>
        <el-table-column align="center" label="备注" prop="remark">
        </el-table-column>
        <el-table-column
          align="center"
          label="创建时间"
          prop="created_at"
          min-width="93"
        >
          <template slot-scope="scope">
            {{ new Date(scope.row.created_at).toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="结束时间"
          prop="expired_at"
          min-width="93"
        >
          <template slot-scope="scope">
            {{ new Date(scope.row.expired_at).toLocaleString() }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import VIP from "@/global/service/vip.js";
import AddVip from "./AddVip.vue";
import { mapState } from "vuex";
import "@/assets/styles/searchTitle.css";
export default {
  components: { AddVip },
  data() {
    return {
      tableData: [],
      pagination: {},
      phone: "",
      addName: "vip-add",
      dialogVisible: false,
    };
  },
  created() {
    this.getVipHistory();
  },
  computed: {
    ...mapState({
      permissions_list: (state) => state.authPermissions,
    }),
  },
  methods: {
    searchVip() {
      this.getVipHistory();
    },
    resetVIPList() {
      this.phone = "";
      this.getVipHistory();
    },
    getVipHistory() {
      VIP.getVipHistory({ phone: this.phone }).then((res) => {
        this.tableData = res.data.list;
        this.pagination = res.data.pagination;
      });
    },
    isPermission(name) {
      return this.permissions_list.some((item) => item == name);
    },
    handleAddVip() {
      if (this.isPermission("vip-add")) {
        this.dialogVisible = true;
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.getVipHistory();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.search-title {
  .el-input {
    width: 280px;
  }
}
.avatar_container {
  width: 80px;
  height: 80px;
  display: inline-block;
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
  }
}
</style>
