<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <div>
      <el-button size="mini" class="add_btn" type="primary" @click="handleAdd"
        >添加角色</el-button
      >
      <div class="list-container">
        <el-table border stripe :data="tableData">
          <el-table-column label="ID">
            <template slot-scope="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="展示名称">
            <template slot-scope="scope">
              <span>{{ scope.row.display_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="描述">
            <template slot-scope="scope">
              <span>{{ scope.row.description }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="handleEdit(scope.row.id)"
                >详情</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                size="mini"
                style="color: #f56c6c"
                :disabled="scope.row.id == '1'"
                @click="handleDelete(scope.row.id)"
                >删除</el-button
              >
              <el-dialog
                title="提示"
                :visible.sync="centerDialogVisible"
                width="30%"
                center
              >
                <span>是否删除该角色</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="centerDialogVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="deleteRole"
                    >确 定</el-button
                  >
                </span>
              </el-dialog>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import Roles from "../../global/service/roles";
export default {
  data() {
    return {
      tableData: [],
      centerDialogVisible: false,
      row_id: 0,
    };
  },
  created() {
    this.getRolesList();
  },
  methods: {
    getRolesList() {
      Roles.getRolesList().then((res) => {
        this.tableData = res.data;
      });
    },
    handleAdd() {
      this.$router.push("add-role");
    },
    handleEdit(id) {
      this.$router.push({
        name: "AddOrUpdateRole",
        params: { role_id: String(id) },
      });
    },
    handleDelete(id) {
      this.row_id = id;
      this.centerDialogVisible = true;
    },
    deleteRole() {
      Roles.deleteRole(this.row_id).then((res) => {
        this.getRolesList();
        this.$message.success(res.msg);
      });
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.add_btn {
  float: right;
  border-radius: 0 !important;
  margin-bottom: 10px !important;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 10%);
}
.el-divider--vertical {
  height: 1.3em;
  vertical-align: sub;
}
</style>
