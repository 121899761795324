<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <div>
      <el-button
        size="mini"
        class="add_btn"
        type="primary"
        @click="centerDialogVisible = true"
        >添加管理员</el-button
      >
      <add-admin
        :visible="centerDialogVisible"
        @dialogClose="centerDialogVisible = false"
        @addDialogClose="Close_dialog"
        :roleList="roleList"
      />
      <el-table stripe :data="tableData" border style="width: 100%">
        <el-table-column type="index" align="center" label="序号">
        </el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <div class="avatar-container">
              <img :src="scope.row.user.avatar_url" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="昵称" align="center" prop="user.nickname">
          <template slot-scope="scope">
            {{ scope.row.user.nickname }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" align="center" label="手机号">
          <template slot-scope="scope">
            {{ scope.row.user.phone }}
          </template>
        </el-table-column>
        <el-table-column
          prop="display_name"
          align="center"
          label="角色"
          :formatter="getName"
        >
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="text"
              style="color: #f56c6c"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
            <el-dialog
              title="提示"
              :visible.sync="deleteDialogVisible"
              width="30%"
              center
            >
              <span>是否删除该管理员</span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="deleteDialogVisible = false"
                  >取 消</el-button
                >
                <el-button type="primary" @click="deleteAdmin">确 定</el-button>
              </span>
            </el-dialog>
            <update-admin
              :visible="editDialogVisible"
              @editdialogClose="Close_editDialog"
              @editdialogQuit="Quit_editDialog"
              :row="row"
              :roleList="roleList"
              :showEdit="editDialogVisible"
              :changeRoleIdList.sync="changeRoleIdList"
            ></update-admin>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import Admin from "@/global/service/admin.js";
import Roles from "@/global/service/roles";
import AddAdmin from "./AddAdmin.vue";
import UpdateAdmin from "./UpdateAdmin.vue";
export default {
  components: { AddAdmin, UpdateAdmin },
  data() {
    return {
      tableData: [],
      roleList: [],
      row_id: [],
      row: {},
      centerDialogVisible: false,
      editDialogVisible: false,
      deleteDialogVisible: false,
      user_id: "",
    };
  },
  created() {
    this.getAdmin();
    this.getRoles();
  },
  methods: {
    Close_dialog() {
      this.centerDialogVisible = false;
      this.getAdmin();
    },
    Close_editDialog() {
      this.editDialogVisible = false;
      this.getAdmin();
    },
    Quit_editDialog() {
      this.editDialogVisible = false;
    },
    getName(row) {
      let display_name = [];
      for (let i = 0; i < row.roles.length; i++) {
        display_name.push(row.roles[i].display_name);
      }
      return display_name.join("、");
    },
    handleDelete(id) {
      this.row_id = id;
      this.deleteDialogVisible = true;
    },
    handleEdit(row) {
      this.row = row;
      let roleIdList = row.roles.map((role) => role.id);
      this.row.roleIdList = roleIdList;
      this.editDialogVisible = true;
    },
    changeRoleIdList(data) {
      this.row.roleIdList = data;
    },
    deleteAdmin() {
      Admin.deleteAdmin(this.row_id).then((res) => {
        this.getAdmin();
        this.$message.success(res.msg);
      });
      this.deleteDialogVisible = false;
    },
    getAdmin() {
      Admin.getAdminList().then((res) => {
        this.tableData = res.data.list;
      });
    },
    getRoles() {
      Roles.getRolesList().then((res) => {
        this.roleList = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.add_btn {
  float: right;
  border-radius: 0;
  margin-bottom: 10px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 10%);
}
/deep/input,
.el-button {
  border-radius: 0;
}
.avatar-container {
  width: 80px;
  height: 80px;
  display: inline-block;
  img {
    text-align: center;
    width: 100%;
    vertical-align: middle;
    height: 100%;
    object-fit: cover;
  }
}
</style>
