export default [
  {
    path: "",
    name: "vip",
    redirect: { name: "vipList" },
    permissions: "vip-history",
    component: { render: (h) => h("router-view") },
    meta: {
      nav: {
        icon: "el-icon-s-home",
        title: "会员管理",
      },
      breadcrumb: {
        title: "会员管理",
      },
    },
    children: [
      {
        path: "vip",
        name: "vipList",
        component: () => import("@/views/VIP/VIP"),
        meta: {
          nav: {
            title: "会员充值",
          },
          breadcrumb: {
            title: "会员充值",
          },
        },
      },
    ],
  },
];
