<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <el-form
      ref="ruleForm"
      label-position="right"
      label-width="100px"
      :rules="rlues"
      :model="ruleForm"
      inline-message
      class="role-form"
    >
      <div class="add-container">
        <div class="add-title">
          <span v-if="role_id">角色编辑</span>
          <span v-else>角色添加</span>
          <el-button
            v-if="role_id"
            size="mini"
            class="add_btn"
            type="primary"
            @click="handleUpdate"
            ><slot name="btn"
              ><i class="el-icon-edit-outline"></i>保存</slot
            ></el-button
          >
          <el-button
            v-else
            size="mini"
            class="add_btn"
            type="primary"
            @click="handleSubmit"
            ><i class="el-icon-plus"></i>添加</el-button
          >
        </div>
        <div class="form-top">
          <el-form-item label="名称：" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入角色的名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="展示名称：" prop="display_name">
            <el-input
              v-model="ruleForm.display_name"
              placeholder="请输入角色的展示名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述：" prop="description">
            <el-input
              type="textarea"
              :rows="2"
              v-model="ruleForm.description"
              placeholder="请输入角色的描述"
            ></el-input>
          </el-form-item>
          <el-form-item label="功能权限：" prop="permissions">
            <el-checkbox
              v-model="checkAll"
              :indeterminate="isAllIndeterminate"
              @change="checkAllChange"
              >所有权限</el-checkbox
            >
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-divider content-position="center">功能权限</el-divider>
    <div
      class="permission-container"
      v-for="(list, group_index) in permissions_list"
      :key="group_index"
    >
      <div class="permission-title">{{ list.display_name }}</div>
      <div class="permission-list">
        <el-checkbox
          :indeterminate="isIndeterminate[group_index]"
          v-model="checkPart[group_index]"
          @change="checkPartChange(checkPart, group_index)"
          class="checkAll-btn"
          >全选</el-checkbox
        >
        <el-checkbox-group
          v-model="ruleForm.permissions[group_index]"
          @change="handleCheckedPermissionsChange(group_index)"
        >
          <el-checkbox
            v-for="item in list.permissions"
            :key="item.id"
            :label="item.id"
            >{{ item.display_name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import Roles from "../../global/service/roles";
export default {
  props: {
    role_id: {
      type: String,
    },
  },
  data() {
    return {
      checkAll: false,
      CheckAllTrue: [],
      CheckAllFalse: [],
      checkPart: [],
      allIdList: [],
      permissions_list: [],
      isIndeterminate: [],
      isAllIndeterminate: false,
      allPermissionsLength: 0,
      roleData: {},
      ruleForm: {
        display_name: "",
        description: "",
        name: "",
        permissions: [],
      },
      rlues: {
        name: {
          required: true,
          message: "名称不能为空",
          trigger: ["blur", "change"],
        },
        display_name: {
          required: true,
          message: "展示名称不能为空",
          trigger: ["blur", "change"],
        },
        description: {
          required: true,
          message: "描述不能为空",
          trigger: ["blur", "change"],
        },

        permissions: [
          {
            validator: this.isCheckPremission,
            trigger: ["handleSumbit"],
          },
        ],
      },
    };
  },
  created() {
    this.getAllPremission();
  },
  methods: {
    AllIndeterminate() {
      this.isAllIndeterminate =
        this.ruleForm.permissions.flat().length > 0 &&
        this.ruleForm.permissions.flat().length < this.allPermissionsLength;
      if (
        this.ruleForm.permissions.flat().length == this.allPermissionsLength
      ) {
        this.checkAll = true;
      }
    },
    isCheckPremission(rule, value, callback) {
      if (value.flat().length == 0) {
        callback("至少选择一项功能权限");
      } else {
        return callback();
      }
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let name = this.ruleForm.name;
          let permission_ids = this.ruleForm.permissions.flat();
          let display_name = this.ruleForm.display_name;
          let description = this.ruleForm.description;
          Roles.addRole({
            name,
            display_name,
            description,
            permission_ids,
          }).then((res) => {
            this.$router.push("role");
            this.$message.success(res.msg);
          });
        }
      });
    },
    handleUpdate() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let name = this.ruleForm.name;
          let permission_ids = this.ruleForm.permissions.flat();
          let display_name = this.ruleForm.display_name;
          let description = this.ruleForm.description;
          Roles.editRole(this.role_id, {
            name,
            display_name,
            description,
            permission_ids,
          }).then((res) => {
            this.$router.push({ name: "Role" });
            this.$message.success(res.msg);
          });
        }
      });
    },
    checkAllChange(val) {
      let length = this.permissions_list.length;
      if (val) {
        this.checkPart = this.CheckAllTrue;
        for (let i = 0; i < length; i++) {
          this.checkPartChange(this.CheckAllTrue, i);
        }
      } else {
        this.checkPart = this.CheckAllFalse;
        for (let i = 0; i < length; i++) {
          this.checkPartChange(this.CheckAllFalse, i);
        }
      }
    },
    checkPartChange(val, index) {
      let checkedId = [];
      let checkedList = this.permissions_list[index].permissions;
      checkedList.map((item) => {
        return checkedId.push(item.id);
      });
      let checkedPermissions = val[index] ? checkedId : [];
      this.ruleForm.permissions[index] = checkedPermissions;
      this.isIndeterminate[index] = false;
      this.AllIndeterminate();
    },
    handleCheckedPermissionsChange(index) {
      let permissions = this.permissions_list[index].permissions;
      let checkedCount = this.ruleForm.permissions[index].length;
      this.checkPart[index] = checkedCount === permissions.length;
      this.$set(
        this.isIndeterminate,
        index,
        checkedCount > 0 && checkedCount < permissions.length
      );
    },
    saveOrUpdate() {
      if (this.role_id) {
        this.updateRoleInfo(this.role_id);
      }
    },
    updateRoleInfo(id) {
      Roles.getRoleDetail(id).then((res) => {
        let dataPermissions = res.data.permissions;
        let permissions = [];
        this.allIdList.map((item) => {
          let data = new Set(item);
          permissions.push(dataPermissions.filter((id) => data.has(id)));
        });
        const { display_name, description, name } = res.data;
        this.ruleForm = { display_name, description, name };
        this.ruleForm.permissions = permissions;
        permissions.map((list, index) => {
          this.checkPart[index] = list.length === this.allIdList[index].length;
          this.isIndeterminate[index] =
            list.length > 0 && list.length < this.allIdList[index].length;
        });
        this.AllIndeterminate();
      });
    },
    getAllPremission() {
      Roles.getAllAuthority().then((res) => {
        let rolesData = res.data.permission_groups;
        rolesData.map((data) => {
          this.checkPart.push(false);
          this.CheckAllTrue.push(true);
          this.CheckAllFalse.push(false);
          this.isIndeterminate.push(false);
          this.ruleForm.permissions.push([]);
          this.permissions_list.push(data);
          let IdList = [];
          data.permissions.map((item) => {
            IdList.push(item.id);
          });
          this.allIdList.push(IdList);
          this.allPermissionsLength += data.permissions.length;
        });
        this.saveOrUpdate();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page-content-container {
  .add-container {
    &,
    .add-title {
      background: #fff;
    }
    .add-title {
      padding: 15px 20px;
      border-bottom: 1px solid #eaeaea;
      span {
        display: inline-block;
      }
      /deep/.add_btn {
        float: right;
        border-radius: 0;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 10%);
        .el-icon-plus,
        .el-icon-edit-outline {
          margin-right: 5px;
        }
      }
    }
    .form-top {
      padding: 15px 20px;
      /deep/.el-input,
      .el-textarea {
        width: 500px;
      }
      /deep/.el-input__inner,
      /deep/.el-textarea__inner {
        border-radius: 0;
      }
    }
  }
  .el-divider {
    margin: 20px 0;
  }
  .permission-container {
    background: #fff;
    margin-top: 20px;
    .permission-title {
      padding: 15px 20px 7px;
      border-bottom: 1px solid #eaeaea;
    }
    .permission-list {
      padding: 15px 20px;
      /deep/.el-form-item__content {
        margin-left: 0 !important;
      }
      .checkAll-btn {
        margin-bottom: 10px;
      }
      .el-checkbox-group {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        row-gap: 10px;
      }
    }
  }
}
</style>
