<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="handleClose"
    title="添加vip用户"
  >
    <el-form
      label-position="right"
      label-width="80px"
      :model="vipForm"
      :rules="vipRules"
      ref="vipForm"
    >
      <el-form-item label="用户：">
        <el-autocomplete
          style="width: 100%"
          class="inline-input"
          v-model="vipForm.phone"
          :fetch-suggestions="querySearch"
          placeholder="请输入手机号"
          @select="handleSelect"
          :trigger-on-focus="false"
        >
          <template slot-scope="{ item }">
            <span style="float: left">{{ item.nickname }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.phone
            }}</span>
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="VIP套餐：">
        <el-select
          v-model="vipForm.vip_sku_id"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in vipType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数量：">
        <el-input-number
          v-model="vipForm.number"
          :min="1"
          :max="12"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="备注：">
        <el-input
          type="textarea"
          rows="3"
          placeholder="请输入内容"
          v-model="vipForm.remark"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="add_btn" @click="handleAddVip"
          >确 定</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import User from "@/global/service/user";
import Vip from "@/global/service/vip";
export default {
  props: {
    visible: {
      type: Boolean,
    },
  },
  watch: {
    visible: function (newVal) {
      this.dialogVisible = newVal;
    },
  },
  data() {
    return {
      userList: [],
      vipForm: {
        user_id: "",
        phone: "",
        vip_sku_id: "",
        number: "",
        remark: "",
      },
      vipType: [],
      vipRules: {},
      dialogVisible: false,
    };
  },
  created() {
    Vip.getVipList().then((res) => {
      this.vipType = res.data;
    });
  },
  mounted() {
    User.getUserList({}).then((res) => {
      let page_size = res.data.pagination.total;
      User.getUserList({ page_size }).then((res) => {
        res.data.list.map((item) => {
          let loadAll = {};
          loadAll.phone = item.phone;
          loadAll.user_id = item.id;
          loadAll.nickname = item.nickname;
          this.userList.push(loadAll);
        });
      });
    });
  },
  methods: {
    querySearch(queryString, cb) {
      var userList = this.userList;
      var results = queryString
        ? userList.filter(this.createFilter(queryString))
        : userList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (userList) => {
        return userList.phone
          ? userList.phone.indexOf(queryString) === 0
          : false;
      };
    },
    handleClose() {
      this.$emit("close_dialog");
      this.$refs.vipForm.resetFields();
    },
    handleAddVip() {
      const { user_id, vip_sku_id, number, remark } = { ...this.vipForm };
      Vip.postVip({ user_id, vip_sku_id, number, remark }).then((res) => {
        this.$message.success(res.msg);
      });
      this.$emit("close_dialog");
      this.$refs.vipForm.resetFields();
    },
    handleSelect(item) {
      this.vipForm.user_id = item.user_id;
      this.vipForm.phone = item.phone;
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  padding: 0 50px;
  /deep/.el-input__inner,
  /deep/.el-textarea__inner {
    border-radius: 0;
  }
  .add_btn {
    border-radius: 0;
    float: right;
  }
}
</style>
