<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <el-table stripe style="width: 100%" :data="tableData">
      <el-table-column
        align="center"
        label="试卷ID"
        prop="id"
      ></el-table-column>
      <el-table-column align="center" label="技能ID" prop="stack_id">
      </el-table-column>
      <el-table-column align="center" label="技能名称" prop="stack.name">
      </el-table-column
      ><el-table-column align="center" label="用户ID" prop="user_id">
      </el-table-column>
      <el-table-column align="center" label="题目总数" prop="question_count">
      </el-table-column>
      <el-table-column align="center" label="正确数量" prop="correct_count">
      </el-table-column>
      <el-table-column align="center" label="错误数量" prop="error_count">
      </el-table-column>
      <el-table-column align="center" label="操作" v-slot="scope">
        <el-button type="text" size="mini" @click="handleEdit(scope.row)"
          >编辑</el-button
        >
        <el-divider class="vertical" direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click="handleDelete(scope.row)"
          >删除</el-button
        >
      </el-table-column>
    </el-table>
    <el-pagination
      :total="pagination.total"
      layout="prev, pager, next"
      background
      :page-size="per_page"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>
<script>
import Exam from "@/global/service/exam.js";
export default {
  data() {
    return {
      tableData: [],
      pagination: "",
      currentPage: 1,
      per_page: 20,
    };
  },
  created() {
    this.getPaper();
  },
  methods: {
    getPaper() {
      Exam.getPaper({ page: this.currentPage }).then((res) => {
        this.tableData = res.data.data.list;
        this.pagination = res.data.data.pagination;
        console.log(res);
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPaper();
    },
  },
};
</script>
<style lang="less" scoped>
.vertical {
  vertical-align: text-top;
}
.el-pagination {
  text-align: center;
}
</style>
