<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <el-form :model="ruleForm" class="search-title" ref="ruleForm">
      <div class="flex">
        <el-date-picker
          v-model="date"
          size="mini"
          type="daterange"
          align="right"
          unlink-panels
          style="width: 280px"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-form-item prop="phone">
          <el-input
            size="mini"
            placeholder="请输入手机号"
            v-model="ruleForm.phone"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="nickname">
          <el-input
            size="mini"
            placeholder="请输入昵称"
            v-model="ruleForm.nickname"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="gender">
          <el-select size="mini" v-model="ruleForm.gender" placeholder="请选择">
            <el-option label="未知" value="0">未知</el-option>
            <el-option label="男" value="1">男</el-option>
            <el-option label="女" value="2">女</el-option>
          </el-select>
        </el-form-item>
        <el-button
          type="primary"
          size="mini"
          plain
          class="search_btn"
          @click="searchUser"
          >查询</el-button
        >
        <el-button size="mini" plain @click="resetSearch" class="search_btn"
          >重置</el-button
        >
      </div>
      <el-button
        type="primary"
        size="mini"
        @click="handleAddUser"
        class="add_btn"
        >添加用户</el-button
      >
    </el-form>

    <el-table :data="tableData" border style="width: 100%; height: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="nickname" label="昵称"> </el-table-column>
      <el-table-column prop="gender" label="性别">
        <template slot-scope="scope">
          <span v-if="scope.row.gender == 0">未知</span>
          <span v-else>{{ scope.row.gender == 1 ? "男" : "女" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column
        prop="created_at"
        label="创建时间"
        column-key="new Date(created_at).toLocaleDateString()"
      >
        <template slot-scope="scope">
          {{ new Date(scope.row.created_at).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column prop="detail" label="详情">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleClick(scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      hide-on-single-page
      layout="prev, pager, next"
      :total="pagination.total"
      @current-change="handleChangePage"
    >
    </el-pagination>
  </div>
</template>
<script>
import User from "@/global/service/user";
import "@/assets/styles/searchTitle.css";
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      date: "",
      ruleForm: {
        phone: "",
        nickname: "",
        gender: "",
      },
      tableData: [],
      pagination: [],
      currentPage: 1,
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    searchUser() {
      const params = { ...this.ruleForm };
      params.gender = Number(this.ruleForm.gender);
      User.getUserList(params).then((res) => {
        this.tableData = res.data.list;
        this.pagination = res.data.pagination;
      });
    },
    resetSearch() {
      this.$refs.ruleForm.resetFields();
      this.getUserList();
    },
    getUserList() {
      User.getUserList({ page: this.currentPage }).then((res) => {
        this.tableData = res.data.list;
        this.pagination = res.data.pagination;
      });
    },
    handleChangePage(val) {
      this.currentPage = val;
      console.log(val);
      this.getUserList();
    },
    handleClick(row) {
      this.$router.push({ name: "userDetail", params: { id: String(row.id) } });
    },
    handleAddUser() {
      this.$router.push({ name: "AddUser" });
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  &.search-title {
    margin-bottom: 10px;
    .flex {
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 140px;
      }
    }
  }
  .search_btn {
    border-radius: 0;
  }
}
</style>
