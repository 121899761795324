import routesCourse from "./routesList/routesCourse";
import routesCamp from "./routesList/routesCamp";
import routesExam from "./routesList/routesExam";
import routesUser from "./routesList/routesUser";
import routesVIP from "./routesList/routesVIP";
import routesAuthority from "./routesList/routesAuthority";
import TheLayout from "@/components/TheLayout.vue";

export default [
  {
    path: "/",
    component: TheLayout,
    children: [
      ...routesCourse,
      ...routesCamp,
      ...routesExam,
      ...routesUser,
      ...routesVIP,
      ...routesAuthority,
    ],
  },
];
