<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <el-form
      ref="userInfo"
      :model="userInfo"
      :rules="userRules"
      label-width="80px"
    >
      <div class="title">
        <span>用户详情</span>
        <el-button size="mini" class="add_btn" type="text" @click="handleSubmit"
          >保存</el-button
        >
      </div>
      <div class="main-form">
        <el-form-item label="手机号:">
          <el-input v-model="userInfo.phone"></el-input>
        </el-form-item>
        <el-form-item label="昵称: ">
          <el-input v-model="userInfo.nickname"></el-input>
        </el-form-item>
        <el-form-item label="性别:">
          <el-radio-group v-model="userInfo.gender" size="mini">
            <el-radio :label="0">未知</el-radio>
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生日:">
          <el-date-picker
            v-model="userInfo.birthday"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-M-d"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="个人简介:">
          <el-input
            type="textarea"
            :rows="2"
            v-model="userInfo.introduction"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import user from "@/global/service/user.js";
export default {
  data() {
    return {
      userInfo: {
        phone: "",
        nickname: "",
        gender: 0,
        birthday: "",
        introduction: "",
      },
      userRules: {},
      disabled: true,
    };
  },
  methods: {
    handleSubmit() {
      user.postAddUser(this.userInfo).then((res) => {
        this.$message.success(res.msg);
        this.$router.push({ name: "userList" });
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form {
  background: #fff;
  .title {
    padding: 15px 25px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
  }
  .main-form {
    padding: 25px 25px;
    width: 550px;
  }
}
</style>
