<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <div class="course-container">
      <el-tree
        :data="courseForm.courses"
        class="course-catalog"
        :props="defaultProps"
        @node-click="handleNodeClick"
      ></el-tree>
      <div class="course-mainer">
        <div class="title">
          <el-button icon="el-icon-s-fold" type="text">展开目录</el-button>
          <el-button type="text">编辑</el-button>
        </div>
        <el-divider>课程</el-divider>
        <div class="el-from-container">
          <el-form
            ref="courseForm"
            :model="courseForm"
            class="course-form"
            :rules="courseRule"
            label-width="120px"
          >
            <el-form-item label="课程ID："> {{ courseForm.id }} </el-form-item>
            <el-form-item label="课程名称：">
              <el-input v-model="courseForm.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="课程介绍：">
              <el-input
                type="textarea"
                :rows="4"
                disabled
                v-model="courseForm.introduction"
              ></el-input>
            </el-form-item>
            <el-form-item label="标签：">
              <el-select v-model="courseForm.difficulty_level" disabled>
                <el-option label="入门" :value="1">入门</el-option>
                <el-option label="初阶" :value="2">初阶</el-option>
                <el-option label="进阶" :value="3">进阶</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课程总学习时长:" prop="total_duration"
              >{{ courseForm.total_duration }}分钟</el-form-item
            >
            <el-form-item label="课程背景：">
              <el-input
                type="textarea"
                disabled
                :rows="3"
                v-model="courseForm.background"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程预览">
              <div class="video-container">
                <video
                  ref="videoPlayer"
                  id="videoPlayer"
                  class="lesson-play video-js vjs-big-play-centered"
                ></video>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Course from "@/global/service/course.js";
import "video.js/dist/video-js.min.css";
import videojs from "video.js/dist/video.min";
import "@/assets/styles/widgets/videoJs.css";

export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      defaultProps: {
        label: "name",
        children: "lessons",
      },
      courseRule: {},
      courseForm: {},
    };
  },
  created() {
    this.getCourseDetail();
  },
  methods: {
    InitVideo() {
      const videoOption = {
        controls: true,
        autoplay: true,
        poster: this.courseForm.cover_url,
        preload: "auto",
        playbackRates: [0.5, 1, 1.5, 2],
        controlBar: {
          // 设置控制条组件
          /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
          children: [
            { name: "playToggle" }, // 播放按钮
            { name: "currentTimeDisplay" }, // 当前已播放时间
            { name: "progressControl" }, // 播放进度条
            { name: "durationDisplay" }, // 总时间
            {
              // 倍数播放
              name: "playbackRateMenuButton",
              playbackRates: [0.5, 1, 1.5, 2, 2.5],
            },
            {
              name: "volumePanel", // 音量控制
              inline: false, // 不使用水平方式
            },
            { name: "pictureInPictureToggle" }, //画中画
            { name: "FullscreenToggle" }, // 全屏
          ],
        },
      };
      this.videoInstance = videojs(this.$refs.videoPlayer, videoOption);
      this.videoInstance.src(this.courseForm.courses[0].lessons[0].url);
    },
    handleNodeClick(data) {
      this.videoInstance.src(data.url);
    },
    getCourseDetail() {
      Course.getCourseDetail(this.id).then((res) => {
        this.courseForm = res.data;
        this.InitVideo();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page-content-container {
  height: 100%;
  .course-container {
    position: absolute;
    overflow-y: hidden;
    left: 0;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    .course-catalog {
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      -ms-overflow-style: none; /* IE 10+ */
      scrollbar-width: none; /* Firefox */
      height: 100%;
      display: inline-block;
      background-color: #f0f2f5;
      padding: 0 15px 15px 0;
    }
    .course-mainer {
      flex: 1;
      background: #fff;
      margin-right: 20px;
      overflow-y: hidden;
      .title {
        display: flex;
        padding: 0 15px;
        margin-bottom: 40px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaeaea;
      }
      .el-from-container {
        height: calc(100% - 120px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        -ms-overflow-style: none; /* IE 10+ */
        scrollbar-width: none; /* Firefox */
        .course-form {
          padding: 0 25px;
          .el-form-item:last-child {
            margin-bottom: 52px;
          }
          .video-container {
            display: block;
            height: 600px;
            .lesson-play {
              outline: none;
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
</style>
