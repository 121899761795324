import Skill from "@/views/Exam/Skill";
import Question from "@/views/Exam/Question";
import Paper from "@/views/Exam/Paper";

export default [
  {
    path: "",
    name: "Exam",
    component: { render: (h) => h("router-view") },
    meta: {
      nav: {
        svg: {
          class: "nav-svg",
          name: "homework",
        },
        title: "测评管理",
      },
      breadcrumb: {
        title: "测评管理",
      },
    },
    children: [
      {
        path: "exam",
        component: { render: (h) => h("router-view") },
        meta: {
          breadcrumb: {
            title: "技能列表",
            replace: true,
          },
        },
        children: [
          {
            path: "skill",
            name: "Skill",
            component: Skill,
            // permission: "",
            meta: {
              title: "技能列表",
              nav: {
                title: "技能列表",
              },
            },
          },
        ],
      },
      {
        path: "exam",
        component: { render: (h) => h("router-view") },
        meta: {
          breadcrumb: {
            title: "问题列表",
            replace: true,
          },
        },
        children: [
          {
            path: "question",
            name: "Question",
            component: Question,
            // permission: "",
            meta: {
              title: "问题列表",
              nav: {
                title: "问题列表",
              },
            },
          },
        ],
      },
      {
        path: "exam",
        component: { render: (h) => h("router-view") },
        meta: {
          breadcrumb: {
            title: "试卷列表",
            replace: true,
          },
        },
        children: [
          {
            path: "paper",
            name: "Paper",
            component: Paper,
            // permission: "",
            meta: {
              title: "试卷列表",
              nav: {
                title: "试卷列表",
              },
            },
          },
        ],
      },
    ],
  },
];
