<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <el-form :model="searchForm" class="search-title">
      <el-form-item class="search-container">
        <el-input
          placeholder="相关岗位"
          size="mini"
          v-model="searchForm.job"
          prefix-icon="el-icon-search"
        ></el-input>
        <el-input
          placeholder="技能点"
          size="mini"
          prefix-icon="el-icon-search"
        ></el-input>
        <el-input
          placeholder="课程名称"
          size="mini"
          prefix-icon="el-icon-search"
        ></el-input>
        <el-input
          placeholder="课程同步ID"
          size="mini"
          prefix-icon="el-icon-search"
        ></el-input>
        <!-- <el-select size="mini" placeholder="课程状态"> </el-select> -->
        <el-select size="mini" v-model="level" placeholder="难度级别">
          <el-option label="入门" value="1"></el-option>
          <el-option label="初阶" value="2"></el-option>
          <el-option label="进阶" value="3"></el-option>
        </el-select>
        <el-select size="mini" v-model="isShow" placeholder="上下架">
        </el-select>
        <el-button size="mini" class="search_btn" plain type="primary"
          >查询</el-button
        >
        <el-button size="mini" class="search_btn" plain>重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" class="add_btn" type="primary"
          >同步系列课</el-button
        >
      </el-form-item>
    </el-form>
    <el-table stripe :data="tableData" border style="width: 100%">
      <el-table-column
        label="课程UUID"
        prop="id"
        align="center"
      ></el-table-column>
      <el-table-column
        label="封面"
        min-width="80"
        prop="cover_url"
        align="center"
      >
        <template slot-scope="scope">
          <el-popover
            placement="right"
            title=""
            class="avatar_container"
            trigger="hover"
          >
            <img
              v-lazy="scope.row.cover_url"
              style="max-height: 500px; width: 500px"
            />
            <img slot="reference" v-lazy="scope.row.cover_url" alt="" />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        label="课程名称"
        prop="name"
        min-width="220"
        align="center"
      ></el-table-column>
      <el-table-column
        label="课程难度"
        prop="difficulty_level_label"
        align="center"
      ></el-table-column>
      <el-table-column label="课程状态" width="80" align="center">
        无
      </el-table-column>
      <el-table-column label="课程数据" align="center">
        <template slot-scope="scope">
          {{ scope.row.courses_count }} 章 /{{ scope.row.lessons_count }} 节/
          {{ scope.row.total_duration }} 分钟
        </template>
      </el-table-column>
      <el-table-column label="相关岗位" align="center"> 0 </el-table-column>
      <el-table-column label="相关技能" align="center"> 0 </el-table-column>
      <el-table-column label="上下架" align="center">
        <template slot-scope="scope">
          <el-switch v-model="isShowList[scope.$index]" active-color="#13ce66">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="100" align="center">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >详情</el-button
          >
          <el-button type="text" style="color: #606266" size="small"
            >设置</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="pagination.total"
      class="pagination"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>
<script>
import Course from "@/global/service/course.js";
import "@/assets/styles/searchTitle.css";
export default {
  data() {
    return {
      tableData: [],
      pagination: {},
      currentPage: 1,
      level: "",
      isShowList: [],
      isShow: "",
      searchForm: {
        job: "",
      },
    };
  },
  created() {
    this.getCourse();
  },
  methods: {
    getCourse() {
      Course.getCourseList({ page: this.currentPage }).then((res) => {
        this.tableData = res.data.data.list;
        this.tableData.map(() => {
          this.isShowList.push(true);
        });

        this.pagination = res.data.data.pagination;
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCourse();
    },
    handleClick(row) {
      this.$router.push({
        name: "courseDetail",
        params: { id: String(row.id) },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-table {
  .avatar_container {
    height: 30px;
    img {
      width: 100%;
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      object-fit: contain;
    }
  }
}
.pagination {
  text-align: center;
}
//.el-form {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   /deep/.el-form-item {
//     margin-bottom: 10px;
//   }
//   /deep/.el-input {
//     width: 110px;
//     margin-right: 5px;
//   }
//   /deep/.el-input__inner {
//     border-radius: 0;
//   }
//   .search_btn,
//   .add {
//     border-radius: 0;
//   }
// }
</style>
