<template>
  <el-form
    :model="projectData"
    ref="projectData"
    class="camps-form"
    label-width="120px"
  >
    <el-form-item label="项目名称" prop="name">
      <el-input v-model="projectData.name" :disabled="disabled"></el-input>
    </el-form-item>
    <el-form-item label="项目介绍" prop="scene">
      <el-input
        type="textarea"
        :rows="4"
        v-model="projectData.scene"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <el-form-item label="教学目标" prop="study_aim">
      <el-input
        type="textarea"
        v-model="projectData.study_aim"
        :rows="6"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <el-form-item label="能力目标" prop="aim_skill">
      <el-input
        class="timeInput"
        v-model="aim_skill"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    projectData: {
      type: Object,
    },
  },
  data() {
    return {
      disabled: true,
      aim_skill: "",
    };
  },
  beforeUpdate() {
    let aim_skill = this.projectData.aim_skills.map((data) => data.name);
    this.aim_skill = aim_skill.join("、");
  },
};
</script>

<style lang="less" scoped>
// .timeInput {
//   margin-right: 15px;
//   width: 50px;
// }
</style>
