<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <el-table border stripe style="width: 100%" :data="tableData">
      <el-table-column align="center" label="Id" prop="id"></el-table-column>
      <el-table-column
        align="center"
        label="技能名称"
        prop="name"
      ></el-table-column>
      <el-table-column
        align="center"
        label="技能图片"
        prop="image_url"
        v-slot="scope"
      >
        <div class="img-container">
          <img v-lazy="scope.row.image_url" />
        </div>
      </el-table-column>
      <el-table-column align="center" label="操作" v-slot="scope">
        <el-button type="text" @click="handleEdit(scope.row)">详情</el-button>
        <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="pagination.total"
      layout="prev, pager, next"
      background
      :page-size="per_page"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>
<script>
import Exam from "@/global/service/exam.js";
export default {
  data() {
    return {
      tableData: [],
      pagination: "",
      currentPage: 1,
      per_page: 20,
    };
  },
  created() {
    this.getStack();
  },
  methods: {
    getStack() {
      Exam.getStack({ page: this.currentPage, page_size: this.per_page }).then(
        (res) => {
          this.tableData = res.data.data.list;
          this.pagination = res.data.data.pagination;
          console.log(res);
        }
      );
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getStack();
    },
  },
};
</script>
<style lang="less" scoped>
.img-container {
  width: 80px;
  height: 80px;
  text-align: center;
  display: inline-block;
  img {
    width: 100%;
    vertical-align: middle;
    object-fit: contain;
  }
}
.el-pagination {
  text-align: center;
}
</style>
