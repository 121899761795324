<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <div class="search-title">
      <div class="search-container">
        <el-input
          size="mini"
          placeholder="项目名称"
          prefix-icon="el-icon-search"
        >
        </el-input>
        <el-button size="mini" type="primary" class="search_btn" plain
          >查询</el-button
        >
        <el-button size="mini" plain class="reset_btn">重置</el-button>
      </div>
      <el-button size="mini" class="add_btn" type="primary"
        >添加实战营</el-button
      >
    </div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column
        prop="id"
        align="center"
        label="项目编号"
      ></el-table-column>
      <el-table-column
        prop="name"
        align="center"
        min-width="240"
        label="项目名称"
      ></el-table-column>
      <el-table-column
        prop="status_label"
        align="center"
        label="项目状态"
      ></el-table-column>
      <el-table-column
        prop="is_online"
        align="center"
        label="上下架"
        v-slot="scope"
        ><el-switch v-model="scope.row.is_online" active-color="#13ce66">
        </el-switch
      ></el-table-column>
      <el-table-column
        prop="teacher_evaluate_price"
        label="批改价格"
      ></el-table-column>
      <el-table-column
        label="操作"
        align="center"
        min-width="145"
        v-slot="scoped"
      >
        <el-button
          type="text"
          size="mini"
          style="color: #606266"
          @click="handleDetail(scoped.row)"
          >详情</el-button
        ><el-divider direction="vertical"></el-divider>
        <el-button
          type="text"
          size="mini"
          style="color: #606266"
          @click="handleUpdate(scoped.row)"
          >批改设置</el-button
        ><el-divider direction="vertical"></el-divider>
        <el-button
          type="text"
          size="mini"
          style="color: #ff4949"
          @click="handleDelete(scoped.row)"
          >删除</el-button
        >
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      :page-size="pagination.per_page"
      background
      :current-page="currentPage"
      layout="prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import "@/assets/styles/searchTitle.css";
import Camps from "@/global/service/camp.js";
export default {
  data() {
    return {
      tableData: [],
      pagination: {},
      currentPage: 1,
    };
  },
  created() {
    this.getProjectList();
  },
  methods: {
    getProjectList() {
      Camps.getProjectsList({ page: this.currentPage }).then((res) => {
        this.tableData = res.data.list;
        this.pagination = res.data.pagination;
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getProjectList();
    },
    handleDetail(row) {
      this.$router.push({
        name: "projectDetail",
        params: { id: String(row.id) },
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.search-title .el-input {
  width: 280px;
}
.el-pagination {
  text-align: center;
}
</style>
