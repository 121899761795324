export default [
  {
    path: "/",
    name: "user",
    redirect: { name: "userList" },
    component: { render: (h) => h("router-view") },
    meta: {
      title: "用户中心",
      nav: {
        icon: "el-icon-s-custom",
        title: "用户中心",
      },
      breadcrumb: {
        title: "用户中心",
      },
    },
    children: [
      {
        path: "",
        component: { render: (h) => h("router-view") },
        meta: {
          breadcrumb: {
            title: "用户",
          },
        },
        children: [
          {
            path: "user",
            name: "userList",
            component: () => import("@/views/User/User.vue"),
          },
          {
            path: "add-user",
            name: "AddUser",
            permissions: "users-add",
            component: () => import("@/views/User/AddUser.vue"),
            meta: {
              breadcrumb: {
                title: "添加",
              },
            },
          },
          {
            path: "user/:id",
            name: "userDetail",
            props: true,
            permissions: ["users-detail", "users-edit"],
            component: () => import("@/views/User/UserDetail.vue"),
            meta: {
              breadcrumb: {
                title: "详情",
              },
            },
          },
        ],
      },
    ],
  },
];
