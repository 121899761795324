<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <div>
      <the-lay-out-detail>
        <template v-slot:tree>
          <el-tree
            default-expand-all
            :data="data"
            :props="defaultProps"
            @node-click="handleNodeClick"
          ></el-tree>
        </template>
        <template v-slot:divider>
          {{ campsForm.name }}
        </template>
        <template v-slot:form>
          <project-info v-if="isBrief" :projectData="campsForm"></project-info>
          <mavon-editor v-else-if="!isBrief" v-model="content" />
        </template>
      </the-lay-out-detail>
    </div>
  </div>
</template>

<script>
import Camps from "@/global/service/camp.js";
import TheLayOutDetail from "../../components/TheLayOutDetail.vue";
import ProjectInfo from "./ProjectInfo.vue";

export default {
  components: { TheLayOutDetail, ProjectInfo },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      data: [],
      campsForm: {},
      defaultProps: {
        children: "children",
        label: "name",
      },
      projectData: {},
      isBrief: true,
      content: "",
    };
  },
  created() {
    this.getProjectDetail();
  },
  methods: {
    getProjectDetail() {
      Camps.getProjectsDetail(this.id).then((res) => {
        this.data.push({ name: res.data.name, children: res.data.pages });
        this.campsForm = res.data;
      });
    },
    handleNodeClick(data) {
      if ("children" in data) {
        this.isBrief = true;
      } else {
        this.isBrief = false;
        this.content = data.contents[0].content.html;
      }
    },
  },
};
</script>

<style></style>

<style></style>
