export default [
  {
    path: "",
    name: "course",
    redirect: { name: "courseList" },
    component: { render: (h) => h("router-view") },
    meta: {
      nav: {
        title: "课程管理",
        svg: {
          class: "nav-svg",
          name: "homework",
        },
      },
      breadcrumb: {
        title: "课程管理",
      },
    },
    children: [
      {
        path: "",
        component: { render: (h) => h("router-view") },
        meta: {
          breadcrumb: {
            title: "系列课",
          },
        },
        children: [
          {
            path: "course",
            name: "courseList",
            permissions: "course-list",
            component: () => import("@/views/Course/Course"),
            meta: {
              nav: {
                title: "系列课",
              },
            },
          },
          {
            path: "course/:id",
            name: "courseDetail",
            props: true,
            permissions: "course-detail",
            component: () => import("@/views/Course/CourseDetail"),
            meta: {
              breadcrumb: {
                title: "详情",
              },
            },
          },
        ],
      },
    ],
  },
];
