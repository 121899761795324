<template>
  <el-form
    :model="campsForm"
    ref="campsForm"
    class="camps-form"
    label-width="120px"
  >
    <el-form-item label="实战营名称" prop="name">
      <el-input v-model="campsForm.name" :disabled="disabled"></el-input>
    </el-form-item>
    <el-form-item label="实战营短名称" prop="short_name">
      <el-input v-model="campsForm.short_name" :disabled="disabled"></el-input>
    </el-form-item>
    <el-form-item label="实战营介绍" prop="description">
      <el-input
        type="textarea"
        v-model="campsForm.description"
        :rows="4"
        :disabled="disabled"
      ></el-input>
    </el-form-item>
    <el-form-item label="实战营学习时长" prop="duration">
      <el-input
        class="timeInput"
        v-model="campsForm.duration"
        :disabled="disabled"
      ></el-input>
      天
    </el-form-item>
    <el-form-item label="状态" prop="is_online">
      <el-radio v-model="campsForm.is_online" :disabled="disabled" label="true"
        >开放中</el-radio
      >
      <el-radio v-model="campsForm.is_online" :disabled="disabled" label="false"
        >筹备中</el-radio
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    campsForm: {
      type: Object,
    },
  },
  data() {
    return {
      disabled: true,
    };
  },
};
</script>

<style lang="less" scoped>
.timeInput {
  margin-right: 15px;
  width: 50px;
}
</style>
