<template>
  <div class="container">
    <div class="catalog" :style="{ display: isShow }">
      <slot name="tree"></slot>
    </div>
    <div class="course-mainer">
      <div class="title">
        <el-button icon="el-icon-s-fold" @click="toggleMenu" type="text"
          >展开目录</el-button
        >
        <el-button type="text">编辑</el-button>
      </div>
      <el-divider><slot name="divider"></slot></el-divider>
      <div class="el-from-container">
        <slot name="form"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: "block",
    };
  },
  methods: {
    toggleMenu() {
      this.isShow = this.isShow == "none" ? "block" : "none";
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: absolute;
  overflow-y: hidden;
  left: 0;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  .catalog {
    height: calc(100% - 26px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    display: inline-block;
    background-color: #f0f2f5;
    padding-bottom: 26px;
    &,
    .el-tree {
      background-color: #f0f2f5;
    }
  }
  .course-mainer {
    flex: 1;
    background: #fff;
    margin: 0 20px;
    overflow-y: hidden;
    overflow-x: auto;
    .title {
      display: flex;
      padding: 0 15px;
      margin-bottom: 40px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eaeaea;
    }
    .el-from-container {
      height: calc(100% - 120px);
      overflow: auto;
      // &::-webkit-scrollbar {
      //   display: none; /* Chrome Safari */
      // }
      // -ms-overflow-style: none; /* IE 10+ */
      // scrollbar-width: none; /* Firefox */
      .camps-form {
        padding: 25px 25px;
        .el-form-item:last-child {
          margin-bottom: 52px;
        }
      }
    }
  }
}
</style>
