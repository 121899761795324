<template>
  <div v-if="showEdit">
    <el-dialog
      title="编辑管理员"
      :visible.sync="editDialogVisible"
      width="400px"
      center
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form :model="adminForm" ref="adminForm" :rules="adminrules">
        <el-form-item label="UID：" prop="user_id" label-width="80px">
          {{ row.user_id }}
        </el-form-item>
        <el-form-item label="昵称：" prop="user" label-width="80px">
          {{ row.user.nickname }}
        </el-form-item>
        <el-form-item label="手机号：" prop="user" label-width="80px">
          {{ row.user.phone }}
        </el-form-item>
        <el-form-item label="角色：" label-width="80px" prop="showRoleIdList">
          <el-select
            multiple
            clearable
            style="width: 100%"
            v-model="adminForm.showRoleIdList"
            @change="changeRole"
            placeholder="请选择角色权限"
          >
            <el-option
              v-for="role in roleList"
              :key="role.id"
              :label="role.display_name"
              :value="role.id"
              style="width: 100%"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Admin from "@/global/service/admin.js";
export default {
  props: {
    visible: {
      type: Boolean,
    },
    roleList: {
      type: Array,
    },
    row: {
      type: Object,
    },
    showEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      editDialogVisible: false,
      adminForm: {
        showRoleIdList: [],
        rowList: {},
      },
      adminrules: {
        showRoleIdList: [
          {
            required: true,
            message: "权限不能为空",
            trigger: ["handleEdit", "change"],
          },
        ],
      },
    };
  },
  watch: {
    visible: function (newVal) {
      this.editDialogVisible = newVal;
      if (newVal) {
        this.adminForm.showRoleIdList = this.row.roleIdList;
        this.rowList = this.row;
      }
    },
  },
  methods: {
    handleClose() {
      this.$refs.adminForm.resetFields();
      this.$emit("editdialogQuit");
    },
    changeRole(value) {
      this.$emit("update:changeRoleIdList", value);
    },
    handleEdit() {
      this.$refs.adminForm.validate((valid) => {
        if (valid) {
          let user_id = this.row.user_id;
          let role_ids = this.adminForm.showRoleIdList;
          this.handleEditAdmin({ user_id, role_ids });
        }
      });
    },
    handleEditAdmin({ user_id, role_ids }) {
      Admin.editAdmin(this.row.id, {
        user_id: user_id,
        role_ids: role_ids,
      }).then((res) => {
        this.$message.success(res.msg);
        this.$refs.adminForm.resetFields();
        this.$emit("editdialogClose");
      });
    },
  },
};
</script>
<style></style>
