<template>
  <div class="page-content-container">
    <bread-crumb></bread-crumb>
    <div class="search-title">
      <div class="search-container">
        <el-input
          size="mini"
          placeholder="实战营名称"
          prefix-icon="el-icon-search"
        >
        </el-input>
        <el-button size="mini" type="primary" class="search_btn" plain
          >查询</el-button
        >
        <el-button size="mini" plain class="reset_btn">重置</el-button>
      </div>
      <el-button size="mini" class="add_btn" type="primary"
        >添加实战营</el-button
      >
    </div>
    <el-table border stripe :data="tableData" style="width: 100%">
      <el-table-column
        align="center"
        label="实战营编号"
        prop="id"
      ></el-table-column>
      <el-table-column align="center" label="职业" prop="job">
      </el-table-column>
      <el-table-column
        align="center"
        label="实战营名称"
        min-width="180"
        prop="name"
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="实战营时长"
        prop="duration"
        v-slot="scope"
      >
        {{ scope.row.duration }}天</el-table-column
      >
      <el-table-column align="center" label="类型" prop="type_label">
      </el-table-column>
      <el-table-column align="center" v-slot="scope" label="可见范围">
        <el-select
          v-model="isShowInWWW[scope.$index]"
          placeholder="请选择"
          size="mini"
        >
          <el-option label="主站可见" value="1">主站可见</el-option>
          <el-option label="主站不可见" value="0">主站不可见</el-option>
        </el-select>
      </el-table-column>
      <el-table-column align="center" v-slot="scope" label="排序">
        <el-input
          class="sort"
          size="mini"
          style="text-align: center"
          v-model="scope.row.sort"
        ></el-input>
      </el-table-column>
      <el-table-column align="center" v-slot="scope" label="上/下架">
        <el-switch v-model="scope.row.is_online" active-color="#13ce66">
        </el-switch>
      </el-table-column>
      <el-table-column
        align="center"
        v-slot="scope"
        min-width="145"
        label="操作"
      >
        <el-button
          type="text"
          size="mini"
          @click="handleDetail(scope.row)"
          style="color: #606266"
        >
          详情</el-button
        >
        <el-divider direction="vertical"></el-divider>
        <el-button
          type="text"
          size="mini"
          @click="handleADs(scope.row)"
          style="color: #606266"
        >
          推广页</el-button
        >
        <el-divider direction="vertical"></el-divider>
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(scope.row)"
          style="color: #ff4949"
        >
          删除</el-button
        >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Camp from "@/global/service/camp.js";
import "@/assets/styles/searchTitle.css";
export default {
  data() {
    return {
      tableData: [],
      isShowInWWW: [],
    };
  },
  created() {
    this.getCampList();
  },
  methods: {
    getCampList() {
      Camp.getCampsList().then((res) => {
        this.tableData = res.data;
        this.tableData.map((data, index) => {
          this.isShowInWWW.push("1");
          data.sort = index;
        });
      });
    },
    handleDetail(row) {
      this.$router.push({ name: "Detail", params: { id: String(row.id) } });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.search-title {
  .el-input {
    width: 280px;
  }
}
.sort {
  /deep/.el-input__inner {
    text-align: center;
  }
}
</style>
