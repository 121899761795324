<template>
  <el-dialog
    title="添加管理员"
    :visible.sync="centerDialogVisible"
    width="400px"
    center
    @close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form :model="adminForm" ref="adminForm" :rules="adminrules">
      <el-form-item label="手机号：" prop="phone" label-width="80px">
        <el-input v-model="adminForm.phone" auto-complete="on"></el-input>
      </el-form-item>
      <el-form-item label="角色：" label-width="80px" prop="role">
        <el-select
          multiple
          clearable
          style="width: 100%"
          v-model="adminForm.role"
          placeholder="请选择角色权限"
        >
          <el-option
            v-for="role in roleList"
            :key="role.id"
            :label="role.display_name"
            :value="role.id"
            style="width: 100%"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleAdd">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Admin from "@/global/service/admin.js";
export default {
  props: {
    visible: {
      type: Boolean,
    },
    roleList: {
      type: Array,
    },
    row: {
      type: Object,
    },
  },
  watch: {
    visible: function (newVal) {
      this.centerDialogVisible = newVal;
    },
  },
  data() {
    return {
      centerDialogVisible: false,
      adminForm: {
        phone: "",
        role: [],
      },
      adminrules: {
        phone: {
          required: true,
          message: "手机号不能为空",
          trigger: ["handleAdd", "change"],
        },
        role: {
          required: true,
          message: "权限不能为空",
          trigger: ["handleAdd", "change"],
        },
      },
    };
  },
  methods: {
    handleClose() {
      this.$emit("dialogClose");
      this.$refs.adminForm.resetFields();
    },
    handleAdd() {
      this.$refs.adminForm.validate((valid) => {
        if (valid) {
          this.getUserId();
        }
      });
    },
    getUserId() {
      Admin.searchAdmin(this.adminForm.phone).then((res) => {
        if (res.data.list.length) {
          let user_id = res.data.list[0].id;
          let role_ids = this.adminForm.role;
          this.addAdmin({ user_id, role_ids });
        } else {
          this.$message.error("该手机未注册");
        }
      });
    },
    addAdmin({ user_id, role_ids }) {
      Admin.addAdmin({ user_id: user_id, role_ids: role_ids }).then((res) => {
        this.$message.success(res.msg);
        this.$emit("addDialogClose");
        this.$refs.adminForm.resetFields();
      });
    },
  },
};
</script>

<style></style>
